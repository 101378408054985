<template>
  <page-container3>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search" ref="searchEle" v-if="searchConfig.fields.length > 0">
        <search-render
          ref="config_render"
          :form="searchConfig"
          :model="searchConfig.model"
          :options="searchConfigOptions"
          :validateInfos="validateInfos"
          :resetFields="resetFields"
          @search="search"
          @resize="handleReSize"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">{{ titleList.title }}</div>
              <component v-if="headerComponent" :is="headerComponent" v-bind="state"></component>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <a-button v-if="titleList.is_create" type="primary" size="small" @click="handleAdd">
                  <plus-outlined />
                  {{ titleList.addModalTitle }}
                </a-button>
                <a-button
                  v-for="btn in head_btn_list"
                  size="small"
                  :key="btn.name"
                  @click="
                    btn.cb(
                      searchConfig.model,
                      state.dataSource,
                      selectedRowKeys,
                      searchConfigOptions,
                      t,
                    )
                  "
                  :type="btn.clazz"
                >
                  <plus-outlined v-if="btn.icon === 'plus-outlined'" />
                  {{ (t && t(`${btn.name}`)) || btn.name }}
                </a-button>
              </a-space>

              <div
                class="ant-pro-table-list-toolbar-setting-item"
                v-if="configState.is_column_config"
              >
                <a-tooltip :title="t('基本列信息')">
                  <a-button size="small" type="primary" danger @click="update_column_org_config">
                    {{ t('设为基础配置') }}
                  </a-button>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('展示自定义列')">
                  <a-switch
                    :checked-children="t('用户')"
                    :un-checked-children="t('原始')"
                    v-model:checked="configState.checkAll"
                    @change="column_change()"
                  />
                </a-tooltip>
              </div>
              <div
                class="ant-pro-table-list-toolbar-setting-item"
                v-if="configState.showCheckAction"
              >
                <a-tooltip :title="t('显示/隐藏操作列')">
                  <a-switch
                    :checked-children="t('操作')"
                    :un-checked-children="t('操作')"
                    v-model:checked="configState.checkAction"
                    @change="columnAction()"
                  />
                </a-tooltip>
              </div>

              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('刷新')">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('导出Excel')">
                  <export-excel-modal
                    :column-list="dynamicColumnItems"
                    :datasource="state.dataSource"
                    :filename="titleList.title"
                    :columns="dynamicColumns"
                    :pageSize="state.pageSize"
                    :current="state.current"
                  />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('密度')">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:">{{ t('默认') }}</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:">{{ t('中等') }}</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:">{{ t('紧凑') }}</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        {{ t('列展示') }}
                      </a-checkbox>
                      <a v-if="!configState.is_column_config" @click="switch_column_config">
                        {{ t('配置') }}
                      </a>
                      <a @click="switch_column_config" v-if="configState.is_column_config">
                        {{ t('关闭配置') }}
                      </a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        style="width: 280px; height: 300px; overflow: scroll"
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="
                          ({ removedIndex, addedIndex }) => {
                            move(removedIndex, addedIndex);
                            if (!configState.is_column_config) {
                              update_column_data();
                            }
                          }
                        "
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div
                            class="ant-pro-table-column-setting-list-item"
                            v-show="configState.is_column_config ? true : column.org_checked"
                          >
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="
                                $event => {
                                  handleColumnChange($event, column);
                                  if (!configState.is_column_config) {
                                    update_column_data();
                                  }
                                }
                              "
                            >
                              {{ column.label }}
                            </a-checkbox>
                            <a-switch
                              v-if="configState.is_column_config"
                              :checked-children="t('显示')"
                              :un-checked-children="t('隐藏')"
                              v-model:checked="column.org_checked"
                              @change="
                                () => {
                                  if (!column.org_checked) {
                                    column.checked = false;
                                  }
                                  handleColumnChange(
                                    { target: { checked: column.checked } },
                                    column,
                                  );
                                }
                              "
                            />
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip :title="t('列设置')">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? t('退出全屏') : t('全屏')">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <s-table
          :bordered="true"
          :height="tableHeight.y"
          :size="state.tableSize"
          v-model:columns="dynamicColumns"
          :data-source="state.dataSource"
          :indentSize="0"
          :autoRowHeight="true"
          :wrapText="true"
          :rowHeight="undefined"
          :rowKey="rowKey ? rowKey : 'id'"
          :loading="state.loading"
          :virtual="state.dataSource.lenght > 1000"
          :customRow="customRow"
          :customHeaderCell="customHeaderCell"
          :customCell="customCell"
          stripe
          sticky
          summary-fixed
          :pagination="{
            current: state.current,
            pageSize: state.pageSize,
            total: state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['50', '100', '200', '500', '1000', '2000'],
          }"
          @change="handleTableChange"
          @resizeColumn="resizeColumn"
          :rowSelection="rowSelection"
          :expandedRowRender="expandedRowRender"
          v-model:selectedRowKeys="selectedRowKeys"
        >
          <template #bodyCell="{ column, index, text, record }">
            <template v-if="column.dataIndex == 'index'">
              {{ index + 1 + state.pageSize * (state.current - 1) }}
            </template>
            <component
              v-else-if="column.cellComponent"
              :is="'Cell_' + column.cellComponent"
              v-bind="{
                column: column,
                index: index,
                text: text,
                record: record,
                pageSize: state.pageSize,
                current: state.current,
                titleList: titleList,
                column_normal_btn_list: column_normal_btn_list,
                column_dropdown_btn_dict: column_dropdown_btn_dict,
                handleOpenEdit: handleOpenEdit,
                handleDelete: handleDelete,
                handleRecover: handleRecover,
              }"
            ></component>
            <template v-else>
              <span :style="column.color ? column.color(record, text) : null">
                {{ column.content ? column.content(record, text) : text }}
              </span>
            </template>
          </template>

          <template #expandedRowRender="{ record }" v-if="expandedRowRenderComponent">
            <component :is="expandedRowRenderComponent(record)" v-bind="record"></component>
          </template>
          <template #summary v-if="summaryColumnsShow">
            <s-table-summary-row>
              <s-table-summary-cell
                :columnKey="item.columnKey"
                v-for="item in summaryColumns"
                :key="item.index"
              >
                <div v-if="item.index == 0">{{ t('合计') }}</div>
                <div v-else-if="!item.summary">&nbsp;</div>
                <template v-if="item.summary" #default="{ total }">
                  <span :style="item.total_color ? item.total_color(total) : null">
                    {{ item.total_content ? item.total_content(total) : total }}
                  </span>
                </template>
              </s-table-summary-cell>
            </s-table-summary-row>
          </template>
        </s-table>
      </a-card>
    </div>
    <form-modal
      v-bind="editModalAdd"
      v-if="editModalAdd.visible"
      :loading="confirmLoading"
      @cancel="
        () => {
          editModalAdd.visible = false;
        }
      "
      @ok="handlerOk"
    />
    <form-modal
      v-bind="editModalUpdate"
      v-if="editModalUpdate.visible"
      :loading="confirmLoading"
      @cancel="
        () => {
          editModalUpdate.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container3>
</template>

<script lang="ts">
import {
  computed,
  createVNode,
  defineComponent,
  onActivated,
  onDeactivated,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ForwardFilled,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  RedoOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { TableColumn } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message, Modal } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import getPageConfig from '@/components/page-model/index';
import { useRoute } from 'vue-router';
import { getObjList, getRangePicker } from '@/utils/function';
import { debounce } from 'lodash-es';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PageModel',
  props: {
    modalType: {
      type: String,
    },
    activeKey: {
      type: String,
    },
  },
  setup(props) {
    const confirmLoading = ref<boolean>(false);
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    let formModalUpdate = false; // 判断显示创建、修改
    const searchEle: any = ref();
    const tableHeight = reactive({ y: window.innerHeight - 300 });
    const onresize: any = debounce(() => {
      if (searchEle.value) {
        tableHeight.y = window.innerHeight - searchEle.value.offsetHeight - 210;
      } else {
        tableHeight.y = window.innerHeight - 210;
      }
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });

    const route = useRoute();
    const path_arr = route.path.split('/');
    const field = {
      modalType: path_arr[path_arr.length - 1],
      column_flag: 'column_' + path_arr[path_arr.length - 1],
    };
    if (props.modalType) {
      field.modalType = props.modalType as string;
      field.column_flag = 'column_' + props.modalType;
    }
    onMounted(() => {});

    watch(
      () => props.activeKey,
      () => {
        if (props.activeKey == props.modalType) {
          reload();
        }
      },
    );
    const summaryColumns = computed(() => {
      return dynamicColumns.value
        .filter((item: any) => {
          if (item.checked === true) {
            return true;
          } else if (item.checked === undefined) {
            return true;
          }
          return false;
        })
        .map((item: any, index: number) => {
          const obj = {
            index: index,
            columnKey: item.dataIndex,
            summary: item.summary,
            color: item.color,
            content: item.content,
          };
          return obj;
        });
    });

    const summaryColumnsShow = computed(() => {
      return dynamicColumns.value.filter(item => item.summary).length > 0;
    });

    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      processData,
      processColumn,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      column_list_change,
      titleList,
      api_fields,
      btn_list,
      head_btn_list,
      event_obj,
      init_request,
      selectedRowKeys,
      rowSelection,
      expandedRowRender,
      expandedRowRenderComponent,
      headerComponent,
      rowKey,
      pageSize,
      customCell,
      customRow,
      customHeaderCell,
    } = getPageConfig(field.modalType);

    const column_normal_btn_list: any = computed(() => {
      return btn_list?.filter((item: any) => item.type == undefined || item.type == 'btn');
    });
    const column_dropdown_btn_dict: any = computed(() => {
      const l = btn_list?.filter((item: any) => item.type == 'dropdown');
      if (l && l.length > 0) {
        return { dropdown_btn: l[0], btn_list: l.slice(1) };
      } else {
        return null;
      }
    });

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }

    const event_obj_refresh_search = (query_data: any) => {
      Object.assign(searchConfig.model, query_data);
      search();
    };
    const event_obj_reload = () => {
      reload();
    };
    const refresh_data = () => {
      state.dataSource = ([] as any).concat(state.dataSource);
    };
    event_obj?.on('refresh_search', event_obj_refresh_search);
    event_obj?.on('refresh', event_obj_reload);
    event_obj?.on('refresh_data', refresh_data);
    event_obj?.on('reset_and_reload', event_obj_reload);
    onActivated(() => {
      reload();
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('refresh_data', refresh_data);
      event_obj?.off('reset_and_reload', event_obj_reload);

      event_obj?.on('refresh_search', event_obj_refresh_search);
      event_obj?.on('refresh', event_obj_reload);
      event_obj?.on('refresh_data', refresh_data);
      event_obj?.on('reset_and_reload', event_obj_reload);
    });
    onDeactivated(() => {
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('refresh_data', refresh_data);
      event_obj?.off('reset_and_reload', event_obj_reload);
    });

    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      column_change,
      replace_column,
    } = useTableDynamicColumns(
      baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
      field.column_flag,
      column_list_change,
    );

    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    Object.keys(route.query).map((key: string) => {
      if (searchConfig.fields.find((item: any) => item.name == key)) {
        searchConfig.model[key] = route.query[key];
      }
    });

    const { stripe, reload, setPageInfo, context: state } = useFetchData(
      queryData,
      {
        current: 1,
        pageSize: pageSize ? pageSize : 50,
        tableSize: 'small', // 'default' | 'middle' | 'small'
        stripe: true,
        init_request: !(init_request === false),
        requestParams: {
          ...searchConfig.model,
          ...getRangePicker(api_fields.rangepicker, searchConfig.model),
        },
      },
      undefined,
      processData,
      processColumn,
      replace_column,
    );
    let sortFieldList: Array<string> = [];
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
      let filter_map: any = {};
      let sort_fields: any = [];
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      if (sorter instanceof Array) {
        sortFieldList = sortFieldList.filter((field: string) => {
          return sorter.find((item: any) => field == item.column.dataIndex) != undefined;
        });
        sorter.map((item: any) => {
          if (!sortFieldList.includes(item.column.dataIndex)) {
            sortFieldList.push(item.column.dataIndex);
          }
        });
        sort_fields = sortFieldList.map((field: string) => {
          if (sorter.find((item: any) => field == item.column.dataIndex).order == 'descend') {
            return `-${field}`;
          } else {
            return field;
          }
        });
      } else if (sorter != undefined && sorter.column != undefined) {
        sortFieldList.length = 0;
        sortFieldList.push(sorter.column.dataIndex);

        if (sorter.order == 'ascend') {
          sort_fields.push(sorter.column.dataIndex);
        }
        if (sorter.order == 'descend') {
          sort_fields.push('-' + sorter.column.dataIndex);
        }
      } else {
        sortFieldList.length = 0;
      }

      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: pagination?.current ? pagination?.current : 1,
        pageSize: pagination?.pageSize ? pagination?.pageSize : 50,
        ...searchConfig.model,
        ...filter_map,
        sort_fields: sort_fields,
      });
      reload();
    };

    const clearFilterAndOrder = (column: any) => {
      if (column.filteredValue != undefined) {
        column.filteredValue.length = 0;
      }
      if (column.sortOrder != undefined) {
        column.sortOrder = false;
      }

      if (column.children != undefined) {
        column.children.forEach((item: any) => clearFilterAndOrder(item));
      }
    };

    const search = () => {
      sortFieldList.length = 0;
      dynamicColumns.value.forEach((column: any) => clearFilterAndOrder(column));
      setPageInfo({
        current: 1,
        ...searchConfig.model,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      });
      reload();
      onresize();
    };
    const handleReSize = () => {
      onresize();
    };
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      Modal.confirm({
        title: () => t('是否删除此项记录?'),
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            removeData({
              org_id: current_org.id,
              ...getObjList(api_fields.delete, record),
            })
              .then((res: any) => {
                message.success(t('删除成功'));
                Object.assign(record, res);
                state.dataSource = ([] as any).concat(state.dataSource);
              })
              .finally(() => {
                resolve(null);
              });
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
      // removeData({
      //   org_id: current_org.id,
      //   ...getObjList(api_fields.delete, record),
      // }).then((res: any) => {
      //   message.success(t('删除成功'));
      //   Object.assign(record, res);
      // });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
        state.dataSource = ([] as any).concat(state.dataSource);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ org_id: current_org.id, aim_org_id: editModalAdd.aim_org_id, ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
          });
      } else {
        updateData({
          ...data,
          org_id: current_org.id,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            state.dataSource = ([] as any).concat(state.dataSource);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
          });
      }
    };

    return {
      column_change,
      columnAction,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,
      summaryColumns,
      summaryColumnsShow,
      expandedRowRender,
      expandedRowRenderComponent,
      headerComponent,
      rowKey,
      t,

      confirmLoading,
      handleAdd,
      handlerOk,
      currentRecord,
      resetFields,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,
      handleReSize,

      configState,
      // is_column_config,
      switch_column_config,
      // change_columns_config,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchEle,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      field,
      api_fields,
      column_normal_btn_list,
      column_dropdown_btn_dict,
      head_btn_list,
      rowSelection: rowSelection ? rowSelection : null,
      selectedRowKeys: selectedRowKeys ? selectedRowKeys : null,
      customCell: customCell ? customCell : () => {},
      customRow: customRow ? customRow : () => {},
      customHeaderCell: customHeaderCell ? customHeaderCell : () => {},
    };
  },
  components: {
    // DragIcon,
    PlusOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ForwardFilled,
    // Draggable,
    // DragContainer,
    FormModal,
    SearchRender,
  },
});
</script>
<style lang="less">
.needCopyRow {
  td {
    position: relative;
  }
}
.large-font {
  font-weight: 500;
  font-size: 17px;
}
.large-number-font {
  font-weight: 500;
  font-size: 24px;
}
</style>